<template>
  <div id="uploadFileField" v-if="isVisible">
    <div class="field">
      <label class="label">
        {{ field.label }}
        <span v-show="field.configs[0].value" class="has-text-danger">*</span>
        <span
          v-show="field.helpBlockText"
          class="icon right tooltip is-tooltip-left"
          :data-tooltip="field.helpBlockText"
        >
          <icon :icon="['fas', 'question-circle']" />
        </span>
      </label>
      <template v-if="isPreview && !fieldValue">
        No file Choosen
      </template>
      <div
        v-if="!isPreview"
        class="file is-right has-name is-fullwidth"
        :class="{ 'is-danger': !valid}"
      >
        <label class="file-label" :class="{ disabled: isDisabled }">
          <input
            :name="field.id"
            class="file-input"
            accept="image/jpg, image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, .csv, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, text/plain, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
            type="file"
            :required="isRequired"
            :disabled="isDisabled || isPreview"
            @change="emitChange($event)"
            @keyup="emitChange($event)"
          />
          <span class="file-cta">
            <span class="file-icon">
              <icon :icon="['fas', 'upload']" />
            </span>
            <span class="file-label">Upload</span>
          </span>
          <span
            class="file-name"
            :title="(fieldValue && fieldValue.name) || ''"
          >
            {{
              (fieldValue && fieldValue.name) ||
                field.placeholder ||
                'No file selected'
            }}
          </span>
        </label>
      </div>
      <div v-if="hasUrl">
        <div class="file is-fullwidth">
          <button class="button is-success" @click="downloadFile">
            <span class="file-icon" :class="{'is-loading': downloading}">
              <icon v-if="isDocument" :icon="['fas', 'eye']" />
              <icon v-else :icon="['fas', 'download']" />
            </span>
            <span class="download-btn-label" :title="field.value.name">{{ field.value.name }}</span>
          </button>
        </div>
      </div>
      <p class="help is-danger" v-show="!valid">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FieldsMixin from '@/components/mixins/FieldsMixin';

export default {
  name: 'UploadFileField',
  mixins: [FieldsMixin],
  props: {
    field: {
      type: Object
    }
  },
  inject: ['isFieldRequired'],
  data() {
    return {
      valid: true,
      errorMessage: '',
      downloading: false,
      hasSelectedNewFile: false,
    };
  },
  computed: {
    isRequired() {
      return this.isFieldRequired(this.field);
    },
    fieldValue: {
      get() {
        return this.field.value;
      },
      set(value) {
        this.field.value = value;
      }
    },
    hasUrl() {
      if (this.fieldValue?.url && !this.hasSelectedNewFile) {
        return true;
      } else {
        return false;
      }
    },
    isDocument() {
      if (this.fieldValue?.name && !this.hasSelectedNewFile) {
        return (this.fieldValue.name.search(/\.pdf$/) >= 0);
      } else {
        return false;
      }
    }
  },
  methods: {
    emitChange() {
      this.fieldValue = this.$el.querySelector('input').files[0];
      if (!this.validate()) {
        return;
      }
      this.$emit('fieldValueHasChanged', { field: this.field });
      this.hasSelectedNewFile = true;
      this.clearValidity();
    },
    validate() {
      const input = this.$el.querySelector('input');
      const file = input.files[0];
      const allowedTypes = [
        '.csv',
        '.xlsx',
        '.xls',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx',
        '.txt',
        'text/plain',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      ];
      const FILE_SIZE = 2.5e8;
      try {
        this.valid = input.checkValidity();
        this.errorMessage = input.validationMessage;
        if (allowedTypes.indexOf(file.type) === -1) {
          this.$notify({
            group: 'alert',
            title: 'Wrong file type!',
            text: 'File type not allowed.',
            type: 'error'
          });
          input.setCustomValidity('Wrong file type');
          this.valid = false;
          this.errorMessage = 'Wrong file type';
        }
        if (file.size >= FILE_SIZE) {
          this.$notify({
            group: 'alert',
            title: 'File too large!',
            text:
              'Sorry! The file you are trying to upload is larger than 250MB.',
            type: 'error'
          });
          input.setCustomValidity('File too large');
          this.valid = false;
          this.errorMessage = 'File too large';
        }
      } catch (ex) {
        console.info('An error occurred while validating the File upload', ex);
      }
      return this.valid;
    },
    copyToClipboard(url) {
      const str = url || this.$refs.fielInputPreview.value;
      const el = document.createElement(`textarea`);
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand(`copy`);
      document.body.removeChild(el);
      this.$notify({
        group: 'alert',
        title: 'Copied to clipboard!',
        text: 'Successfuly copied to clipboard.',
        type: 'success'
      });
    },
    async downloadFile(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      try {
        this.downloading = true;
        const response = await axios.get($formsConfig.core.api.file, {
          params: {
            folder: this.fieldValue.folder,
            owner: this.fieldValue.owner,
            file: this.fieldValue.name,
          }
        });
        window.open(response.data.url, '_blank');
      } catch (error) {
        this.$notify({
          group: 'alert',
          title: 'Something went wrong!',
          text:
            (error && error.message) ||
            'We were unable to retrive data.',
          type: 'error'
        });
        console.error(error);
      } finally {
        this.downloading = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#uploadFileField {
  @extend %fieldContainer;
  .download-btn-label {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: inherit;
    text-overflow: ellipsis;
    max-width: 150px;
  }
  .file-name {
    max-width: 195px;
  }
  .file.is-danger {
    .file-name {
      border-color: $danger;
    }
  }
  .file-label.disabled {
    .file-cta, .file-name {
      color: #ddd;
      background-color: #f7f7f7;
      border-color: #b5b5b5;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
</style>
