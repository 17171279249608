<template>
  <div id="formField" v-if="isVisible">
    <div class="card">
      <header class="card-header">
        <span class="card-header-title">
          {{ field.label }}
        </span>
        <span
          v-show="field.helpBlockText"
          class="icon right tooltip is-tooltip-left"
          :data-tooltip="field.helpBlockText"
        >
          <icon :icon="['fas', 'question-circle']" />
        </span>
      </header>
      <template v-if="!loadingFields">
        <tabs v-if="field.multiple" style="margin-top: .75rem;" small>
          <tab
            v-for="(tab, idx) in formsTabs"
            :key="idx"
            :is-active="isActive === idx"
            @click.native="onAddTabClick({ index: idx })"
          >
            <icon v-if="tab.add" :icon="['fas', tab.add]" style="margin-right: .75rem;" />
            {{ tab.title }}
            <icon
              v-if="tab.delete && formsTabs.length > 2 && !isPreview"
              :icon="['fas', tab.delete]"
              style="margin-left: .5rem;"
              @click.prevent.stop="records.splice(idx, 1); isActive = 0;"
            />
          </tab>
        </tabs>
        <div class="card-content">
          <div class="record-container" v-for="(record, idx) in records" :key="record.id">
            <div v-show="isActive === idx" class="record">
              <div class="columns is-multiline">
                <div
                  v-for="field in record.form.fields"
                  :key="'field-' + field.id"
                  class="column is-3"
                >
                  <component
                    v-if="field.type !== 'form'"
                    :is="field.keyName"
                    :field="field"
                    @fieldValueHasChanged="onFieldValueChange({ ...$event, record: record.id })"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import deepClone from 'lodash.clonedeep';
import { uuidv4 } from '@/helpers/general';
import SingleLineInputField from '@/components/fields/SingleLineInputField';
import DropDownInputField from '@/components/fields/DropDownInputField';
import NumericInputField from '@/components/fields/NumericInputField';
import CheckboxField from '@/components/fields/CheckboxField';
import EmailInputField from '@/components/fields/EmailInputField';
import TextAreaField from '@/components/fields/TextAreaField';
import TimeInputField from '@/components/fields/TimeInputField';
import RadioButtonField from '@/components/fields/RadioButtonField';
import DateInputField from '@/components/fields/DateInputField';
import UploadFileField from '@/components/fields/UploadFileField';
import FieldsMixin from '@/components/mixins/FieldsMixin';
export default {
  name: 'FormField',
  mixins: [FieldsMixin],
  components: {
    SingleLineInputField,
    DropDownInputField,
    NumericInputField,
    CheckboxField,
    EmailInputField,
    TextAreaField,
    TimeInputField,
    RadioButtonField,
    DateInputField,
    UploadFileField
  },
  props: {
    field: {
      type: Object,
      default: function () {
        return {}
      }
    },
    subrecords: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      forms: [],
      currentDependencies: [],
      dependencyMatched: false,
      currentRecord: {},
      isActive: 0,
      records: [],
      loadingFields: true,
    }
  },

  async beforeMount() {
    const response = await axios.get($formsConfig.core.api.forms)
    this.forms = response.data;
    this.fillRecordData();
    this.loadingFields = false;
  },
  watch: {
    isActive(newVal) {
      this.currentRecord = this.records[newVal];
    },
    subform(newVal) {
      if (newVal) {
        if (this.subrecords.length) {
          this.records = this.subrecords.filter(sub => sub.fieldId === this.field.id);
        } else {
          this.records = [{
            id: uuidv4(),
            form: this.cloneSubform(newVal),
          }];
        }
      }
    },
    isPreview(nValue, oValue) {
      if (!nValue) {
        this.loadingFields = true;
        this.currentDependencies = [];
        this.dependencyMatched = false;
        this.fillRecordData();
        this.$nextTick(() => {
          this.loadingFields = false;
        });
      }
    }
  },
  computed: {
    isPreview() {
      // Means user is previwing the record and not preforming an edit.
      return this.$route?.query?.preview ?? false;
    },
    formsTabs() {
      const tabs = [];
      this.records.forEach((record, idx) => {
        tabs.push({
          delete: `trash-alt`,
          title: `Record ${idx + 1}`,
        });
      });
      tabs.push({
        add: `plus`,
        title: `Add Record`,
      })
      return tabs;
    },
    subform() {
      const subformId = this.field.value;
      return this.forms.find(form => form._id === subformId) || {};
    }
  },
  methods: {
    cloneSubform(form) {
      const clone = deepClone(form);
      clone.fields.forEach(f => {
        if (f.type !== 'form') {
          f.recordId = uuidv4()
        }
      });
      return clone;
    },
    onAddTabClick({ index }) {
      if (index < this.records.length) {
        this.isActive = index;
        return;
      }
      if (this.isPreview) {
        return;
      }
      // Add new record
      this.records.push({
        id: uuidv4(),
        form: this.cloneSubform(this.subform),
      });
      this.isActive = this.records.length - 1;
    },
    onFieldValueChange({ field, record }) {
      this.currentRecord?.form?.fields.map(f => {
        if (f.id === field.id) {
          return field;
        }
      });
      if (!this.currentDependencies.length) {
        return;
      }
      this.dependencyMatched = false;
      this.currentDependencies.forEach(dep => {
        this.matchDependency(field, dep, record);
      });
      if (this.dependencyMatched) {
        this.$root.$emit('onDependenciesUpdate', { field });
      }
    },
    fillRecordData() {
      if (!this.subform.fields) {
        return;
      }
      this.subform.fields.forEach(field => {
        if (field.type === 'form') {
          return;
        }
        if (field.dependencies.length) {
          this.currentDependencies.push(field.dependencies);
        }
      });
      // Start activating dependencies before mounting fields.
      this.currentDependencies.forEach(dep => {
        this.subform.fields.forEach(field => {
          this.matchDependency(field, dep);
        });
      });
    },
    matchDependency(field, dependencies, record) {
      let fieldValue = null;
      if (field.selectedOption) {
        fieldValue = field.selectedOption;
      } else if (field.selectedOptions) {
        fieldValue = field.selectedOptions;
      } else {
        fieldValue = field.value;
      }
      for (let i = 0; i < dependencies.length; i++) {
        if (field.id === dependencies[i].selectedDepedencyField.id) {
          this.dependencyMatched = true;
          let currentRecord = null;
          let target = null;
          if (record) {
            // Find the right dependency in records to match the right object reference.
            currentRecord = this.records.find(r => r.id === record);
            currentRecord.form.fields.forEach(f => {
              for (const dep of f.dependencies) {
                if (dep.id === dependencies[i].id) {
                  target = dep
                  break;
                }
              }
            });
          } else {
            target = dependencies[i]
          }
          if (fieldValue === target.valueToMatch) {
            target.isActive = true;
          } else if (
            target.valuesToMatch.length > 0 &&
            fieldValue !== undefined
          ) {
            const values = target.valuesToMatch;
            if (values.length === fieldValue.length) {
              target.isActive = values.every(element =>
                fieldValue.includes(element)
              );
            } else {
              target.isActive = false;
            }
          } else {
            target.isActive = false;
          }
        }
      }
      return dependencies;
    },
  }
};
</script>

<style lang="scss" scoped>
  #formField {
    @extend %fieldContainer;
    .card {
      width: 100%;
      overflow-y: auto;
    }
  }
</style>
