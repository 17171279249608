<template>
  <div id="checkBoxField" v-if="isVisible">
    <div class="field">
      <label class="label">
        {{ field.label }}
        <span v-show="isRequired" class="has-text-danger">*</span>
        <span
          v-show="field.helpBlockText"
          class="icon right tooltip is-tooltip-left"
          :data-tooltip="field.helpBlockText"
        >
          <icon :icon="['fas', 'question-circle']" />
        </span>
      </label>
      <div v-if="isPreview" class="control">
        <input
          class="input"
          type="text"
          readonly
          :value="fieldValue"
        />
      </div>
      <div
        v-else
        :class="{ 'is-danger': !valid }"
        class="control"
        v-for="(option, index) in activeOptions"
        :key="index"
      >
        <label class="checkbox">
          <input
            :disabled="isDisabled || isPreview"
            v-model="fieldValue"
            type="checkbox"
            :name="field.id"
            :value="option.value"
            @change="emitChange($event)"
          />
          {{ option.label }}
        </label>
      </div>
      <p class="help" :class="{ 'is-danger': !valid }" v-show="!valid">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import FieldsMixin from '@/components/mixins/FieldsMixin';

export default {
  name: 'CheckboxField',
  mixins: [FieldsMixin],
  props: {
    field: {
      type: Object
    }
  },
  inject: ['isFieldRequired'],
  data() {
    return {
      activeOptions: this.field.options,
      valid: true,
      errorMessage: ''
    };
  },
  created() {
    if (!this.field.selectedOptions.length) {
      this.field.selectedOptions = this.field.options
        .map(option => {
          if (option.checked) {
            return option.value;
          }
        })
        .filter(value => typeof value !== 'undefined');
    }
  },
  computed: {
    fieldValue: {
      get() {
        return this.field.selectedOptions;
      },
      set(value) {
        this.field.selectedOptions = value;
      }
    },
    isRequired() {
      return this.isFieldRequired(this.field);
    }
  },
  methods: {
    validate() {
      if (this.isRequired && this.field.selectedOptions.length < 1) {
        this.valid = false;
        this.errorMessage = 'Please, select one.';
      } else {
        this.valid = true;
      }
      return this.valid;
    }
  }
};
</script>

<style lang="scss" scoped>
#checkBoxField {
  @extend %fieldContainer;
  @extend %selectInput;
}
</style>
